
// coinType = 0 for NON EVM crypto, 1 for EVM based crypto
const coins = [
    {
        id: 0,
        name: "GXCOIN",
        symbol: "GLX",
        image: "/logo.png",
        price: 0,
        change: 0,
        volume: 0,
        network: "Mainnet",
        isToken: false,
        coinType: 0,
        tokenContract: ""
    },
    {
        id: 1,
        name: "Bitcoin",
        symbol: "BTC",
        image: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "Mainnet",
        isToken: false,
        coinType: 0,
        tokenContract: ""

    },
    {
        id: 2,
        name: "Ethereum",
        symbol: "ETH",
        image: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "ETH",
        isToken: false,
        coinType: 1,
        tokenContract: ""
    },
    {
        id: 3,
        name: "Tron",
        symbol: "TRX",
        image: "https://cryptologos.cc/logos/tron-trx-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "TRX",
        isToken: false,
        coinType: 1,
        tokenContract: ""
    },
    {
        id: 4,
        name: "Litecoin",
        symbol: "LTC",
        image: "https://cryptologos.cc/logos/litecoin-ltc-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "Mainnet",
        isToken: false,
        coinType: 0,
        tokenContract: ""
    },
    {
        id: 5,
        name: "Arbitrum",
        symbol: "ARB",
        image: "https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "ARB",
        isToken: false,
        coinType: 1,
        tokenContract: ""
    },
    {
        id: 6,
        name: "Dogecoin",
        symbol: "DOGE",
        image: "https://cryptologos.cc/logos/dogecoin-doge-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "Mainnet",
        isToken: false,
        coinType: 0,
        tokenContract: ""
    },
    {
        id: 7,
        name: "Binance Chain",
        symbol: "BNB",
        image: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "BSC",
        isToken: false,
        coinType: 1,
        tokenContract: ""
    },
    {
        id: 8,
        name: "Tether",
        symbol: "USDT",
        image: "https://cryptologos.cc/logos/tether-usdt-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "TRX",
        isToken: true,
        coinType: 1,
        tokenContract: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
    },
    {
        id: 9,
        name: "Polygon",
        symbol: "MATIC",
        image: "https://cryptologos.cc/logos/polygon-matic-logo.png?v=032",
        price: 0,
        change: 0,
        volume: 0,
        network: "MATIC",
        isToken: false,
        coinType: 1,
        tokenContract: ""
    }
]

const coinHistoryUrl = (coin) => {
    return `https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=7`;
}


export { coins, coinHistoryUrl }