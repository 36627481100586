import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postHelper } from "../../helpers/apiHelper";
import {storedata, fetchData} from "../../helpers/security";
import processpic from "../../assets/img/progress.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withSessionCheck from '../../middleware/withauthcheck';

function PinEntry() {
    const [pin, setPin] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only numeric input and limit to one character
        if (/^\d$/.test(value)) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);

            // Move focus to the next input field
            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newPin = [...pin];
            
            // If the current input is not empty, clear it
            if (newPin[index] !== '') {
                newPin[index] = '';
                setPin(newPin);
            } else if (index > 0) {
                // If the current input is already empty, move focus to the previous input
                inputRefs.current[index - 1].focus();
                newPin[index - 1] = '';
                setPin(newPin);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const pinCode = pin.join('');
        const token = fetchData('user');

        const data = {
            otp: pinCode
        };

        const response = await postHelper('user/verifypin', data, token);
            if(response.type == "auth"){
                navigate('/sessionexpired');
            }
            // console.log(response);
            if(response.error){
                // error toast
                toast(response.msg, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    // transition: "Bounce",
                    });
            }else{
                // success toast
                toast(response.msg, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    // transition: "Bounce",
                    onClose: () => navigate('/seed'),
                    });
            }
        

        // Add your form submission logic here (e.g., send the pinCode to the server)
    };

    const handleResend = async (e) => {
        e.preventDefault();
        const token = fetchData('user');
        const response = await postHelper('user/resendpin', {}, token);
        if(response.type == "auth"){
            navigate('/sessionexpired');
        }
        if(response.error){
            // toast error msg
            toast(response.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // transition: "Bounce",
                });
        }else{
            // toast success msg and restart timer
            toast(response.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // transition: "Bounce",
                });

            setTimeLeft(30);
            setCountdown(true);
        }
    };

    const [timeLeft, setTimeLeft] = useState(30); // 300 seconds = 5 minutes
    const [countdown, setCountdown] = useState(true);

  useEffect(() => {
    
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      
      return () => clearInterval(timerId); // Clear interval on component unmount
    }else{
        setCountdown(false);
    }
  }, [timeLeft]);

  // Function to format time in mm:ss format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

    return (
        <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-4 mx-auto align-self-center py-4">
            <h1 className="mb-3">
                <span className="text-secondary fw-light">Enter your</span><br />6-digit OTP
            </h1>
            <p className="text-secondary mb-4">Please enter the 6-digit OTP sent to your registered email id.</p>

            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between mb-4">
                    {pin.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            className="form-control text-center mx-1"
                            style={{ width: '50px' }}
                            value={digit}
                            maxLength="1"
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    ))}
                </div>
                <div className="col-11 col-sm-11 mt-auto mx-auto py-4">               
                <div className="row">
                    <div className="col-auto text-center mx-auto">
                        {
                            countdown ? 
                            <span className="progressstimer">
                                <img src={processpic} alt="" />
                                <span className="timer" id="timer">{formatTime(timeLeft)}</span>
                            </span> : 
                            <p className="mb-3">
                                <span className="text-muted">Didn't receive it yet?</span> 
                                <a href="#" onClick={handleResend}>Resend OTP</a>
                            </p>
                        }
                    
                    </div>
                    
                </div>
                </div>
                <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg">
                        Submit
                    </button>
                </div>
            </form>
            <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition= "bounce"    
            />
        </div>
    );
}

export default withSessionCheck(PinEntry);
