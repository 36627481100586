import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Dropdown , InputGroup} from 'react-bootstrap';
import logo from '../assets/img/logo.png';
import { coins } from '../constants/coins';
import { QrCodeScan } from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import { postHelper } from '../helpers/apiHelper';
import { fetchData } from '../helpers/security';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {pinverify, transfer} from '../reducers/actions/menuaction';
import { useTranslation } from 'react-i18next';



const SendPage = () => {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fromImage, setFromImage] = useState(coins[0].image);
  const [fromData, setFromData] = useState(coins[0].name);
  const [fromValue, setFromValue] = useState('');
  const [toImage, setToImage] = useState(coins[1].image);
  const [toData, setToData] = useState(coins[1].name);
  const [toValue, setToValue] = useState('');

  const [amount, setAmount] = useState('0.00');
  const [coupon, setCoupon] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('GLX');
  const [cim, setcim] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(true);

  const navigate = useNavigate();

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(coins[currency].symbol);
    setcim(currency);
  };

  const handleSwap = () => {
    alert(`Swapped ${fromValue} of ${fromData} to ${toValue} of ${toData}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const _to = coupon;
    const amn = amount * 10 ** 8;
    // console.log(menu.userData).data.pin;
    if(menu.userData.data.pin === 0){
      navigate('/setpin');
      return;
    }else{
      if(coins[cim].symbol === 'GLX'){
        if(coupon == '' || amount == '0.00'){
          toast("Please Enter Address and Amount", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            
          })
          return;
        }
        // console.log(menu.userData.balance, parseFloat(amount));?
        if(menu.userData.balance < parseFloat(amount)){
          toast("Insufficient Balance", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            
          })
          return;
        }

        dispatch(transfer({to: _to, amount:amn}));
        dispatch(pinverify());
      }else{
        toast("Only GLX is Transferable", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
        })
      }
      
    }
    // const res = await postHelper('blockchain/transfer', {to: _to, amount:amn}, token);
    // console.log(res); 
    // toast(res.message.result, {
    //   position: "bottom-center",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    if(menu.userData.balance > parseFloat(e.target.value)){
      e.target.style.borderColor = 'green';
    }else{
      e.target.style.borderColor = 'red';
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-auto">
          <Card className="shadow-sm">
            <Card.Body className="p-1">
              <div className="avatar avatar-44 rounded-15 shadow-sm">
                <img src={logo} alt="Company" className="rounded-15" />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col align-self-center ps-0">
          <p className="mb-1 text-color-theme">{t('coinname')} {t('wallet')}</p>
          <p className="text-muted size-12">{t('tag')}</p>
        </div>
        <div className="col-auto">
          <Button href="#" variant="default" className="btn-44 shadow-sm">
            <QrCodeScan size={24} />
          </Button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-auto">
          <div className="avatar-group">
            {coins.map((src, index) => (
              <div key={index} className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1" onClick={() => handleCurrencyChange(index)}>
                <img src={src.image} alt="User" className="rounded-15" />
              </div>
            ))}
          </div>
        </div>
        <div className="col align-self-center ps-0">
          <p className="text-muted small"></p>
        </div>
        {/* <div className="col-auto">
          <Button variant="default" className="btn-44 shadow-sm">
            <img src={coins[cim].image} alt="Company" className="rounded-15"  style={{width: '30px', height: '30px'}}/>
          </Button>
        </div> */}
      </div>


      <div className="row">
        <div className="col-12 mb-4">
          <Form.Group controlId="coupon">
            <Form.Label>{t('to')} {coins[cim].name} ({coins[cim].symbol}) {t('address')}</Form.Label>
            <div className="col-auto float-end">
          {/* <Button className="btn-44 shadow-sm"> */}
            <img src={coins[cim].image} alt="Company" className="rounded-15"  style={{width: '30px', height: '30px'}}/>
          {/* </Button> */}
        </div>
            <InputGroup>
              <Form.Control
                type="text"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder={`${coins[cim].symbol} ${t('address')}`}
                // isValid={true}
                // isInvalid={appliedCoupon}
              />
              
            </InputGroup>
          </Form.Group>
        </div>

      </div>

      <div className="row">
        <div className="col-12 mb-4">
          <Form.Group controlId="coupon">
            <Form.Label>{t('amount')}</Form.Label>
            <div className="col-auto float-end">
            <p>{menu.userData ? menu.userData.balance.toFixed(8) : 0} {t('symbol')}</p>
            </div>
            <InputGroup>
              <Form.Control
                type="number"
                value={amount}
                onChange={handleAmountChange}
                placeholder={coins[cim].symbol + ' ' + t('amount')}
                // isValid={true}
                // isInvalid={appliedCoupon}
              />
              
            </InputGroup>
          </Form.Group>
        </div>

      </div>

      
      {/* Select Amount */}
      

      {/* Coupon Code */}
      

      {/* Amount Breakdown */}
      <Card className="mb-4">
        <Card.Body>
          <div className="row mb-3">
            <div className="col">
              <p>{t('transfer')}</p>
            </div>
            <div className="col-auto text-end">
              <p className="text-muted">{amount} {selectedCurrency}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <p>{t('burnfee')} (0.001%)</p>
            </div>
            <div className="col-auto text-end">
              <p className="text-muted">{amount * 0.001} {selectedCurrency}</p>
            </div>
          </div>
          {/* <div className="row mb-3">
            <div className="col">
              <p>Estimated Transaction Fees</p>
            </div>
            <div className="col-auto text-end">
              <p className="text-muted">{amount * 0.001} {selectedCurrency}</p>
            </div>
          </div> */}
          <div className="row fw-medium">
            <div className="col-12">
              <hr className="dashed-line mb-3" />
            </div>
            <div className="col">
              <p>{t('deduction')}</p>
            </div>
            <div className="col-auto text-end">
              <p className="text-muted">{parseFloat(amount * 0.001) + parseFloat(amount)} {selectedCurrency}</p>
            </div>
          </div>
        </Card.Body>
      </Card>

   
      {/* Add to Wallet */}
      <div className="row mb-4">
        <div className="col-12">
          <Button onClick={handleSubmit} variant="default" className="btn-lg shadow-sm w-100">
            {t('send')} {selectedCurrency}
          </Button>
        </div>
      </div>

      <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </>
  );
};

export default SendPage;
