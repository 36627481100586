import React, { useState } from 'react';
import { postHelper } from '../../helpers/apiHelper';
import { storedata } from '../../helpers/security';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MnemonicSubmission() {
    const {t} = useTranslation();
    const [mnemonic, setMnemonic] = useState('');
    const navigate = useNavigate();
    const handleChange = (e) => {
        setMnemonic(e.target.value);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const tid = toast.loading("Please wait...", )
        const data = { 
            seed: mnemonic
         };
        const res = await postHelper('user/recover', data, "");
            if(res.error){
                toast.update(tid, { render: res.msg, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false })
            }else{
                storedata("user", res.token, "login");
                if(res.tfa){
                    navigate("/tfa");
                }else{
                toast.update(tid, { render: res.msg, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false,onClose: () => navigate("/dashboard") })
                }
            }

        // Add your form submission logic here (e.g., send the mnemonic to the server)
    };

    return (
        <div className="col-11 col-sm-11 col-md-8 col-lg-6 mx-auto align-self-center py-4">
            <h1 className="mb-3">
                <span className="text-secondary fw-light">{t('enter')}</span><br />{t('mnemonic_phrase')}
            </h1>
            <p className="text-secondary mb-4">{t('mnemonicdesc')}</p>

            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <textarea
                        className="form-control"
                        rows="6"
                        value={mnemonic}
                        onChange={handleChange}
                        placeholder={t('mnemonic_here')}
                    />
                </div>
                <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg">
                        {t('recover')}
                    </button>
                </div>
            </form>
            <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition= "bounce"    
            />
        </div>
    );
}

export default MnemonicSubmission;
