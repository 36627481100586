import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postHelper } from "../../helpers/apiHelper";
import { storedata, fetchData } from "../../helpers/security";
import processpic from "../../assets/img/progress.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withSessionCheck from '../../middleware/withauthcheck';
import { useTranslation } from 'react-i18next';

function SetTransactionPin() {
    const [pin, setPin] = useState(['', '', '', '', '', '']);
    const [confirmPin, setConfirmPin] = useState(['', '', '', '', '', '']);
    const [isConfirming, setIsConfirming] = useState(false);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleChange = (e, index) => {
        const value = e.target.value;

        if (/^\d$/.test(value)) {
            const newPin = isConfirming ? [...confirmPin] : [...pin];
            newPin[index] = value;
            isConfirming ? setConfirmPin(newPin) : setPin(newPin);

            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newPin = isConfirming ? [...confirmPin] : [...pin];

            if (newPin[index] !== '') {
                newPin[index] = '';
                isConfirming ? setConfirmPin(newPin) : setPin(newPin);
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();
                newPin[index - 1] = '';
                isConfirming ? setConfirmPin(newPin) : setPin(newPin);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isConfirming) {
            setIsConfirming(true);
            setTimeout(() => inputRefs.current[0].focus(), 0);
        } else {
            const pinCode = pin.join('');
            const confirmPinCode = confirmPin.join('');

            if (pinCode === confirmPinCode) {
                const token = fetchData('user');
                const data = {
                    pinCode: pinCode
                };

                const response = await postHelper('user/transactionpin', data, token);
                if (response.type === "auth") {
                    navigate('/sessionexpired');
                }
                if (response.error) {
                    toast(response.msg, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast(response.msg, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClose: () => navigate('/dashboard'),
                    });
                }
            } else {
                toast.error(t('pinnotmatch'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                // Reset PINs
                setPin(['', '', '', '', '', '']);
                setConfirmPin(['', '', '', '', '', '']);
                setIsConfirming(false);
                setTimeout(() => inputRefs.current[0].focus(), 0);
            }
        }
    };

    

    const [timeLeft, setTimeLeft] = useState(30);
    const [countdown, setCountdown] = useState(true);

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearInterval(timerId);
        } else {
            setCountdown(false);
        }
    }, [timeLeft]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-4 mx-auto align-self-center py-4">
            <h1 className="mb-3">
                <span className="text-secondary fw-light">{t('setyour')}</span><br />{t('transaction')} PIN
            </h1>
            <p className="text-secondary mb-4">
                {isConfirming ? t('reenterpin') : t('setpin')}
            </p>

            <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between mb-4">
                    {(isConfirming ? confirmPin : pin).map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            className="form-control text-center mx-1"
                            style={{ width: '50px' }}
                            value={digit}
                            maxLength="1"
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    ))}
                </div>

                <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg">
                        {isConfirming ? t('submit') : t('next')}
                    </button>
                </div>
            </form>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default withSessionCheck(SetTransactionPin);
