import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { coins } from '../../constants/coins';
import AreaChart from './charts';
import { useTranslation } from 'react-i18next';
const ConnectionSwiper = () => {
  const dt = useSelector((state) => state.menu.prices);
  const { t } = useTranslation();
  useEffect(() => {
    console.log(dt);
  }, [dt]);

  return (
    <>
    <div className="row mb-2">
                <div className="col">
                    <h6 className="title">{t('othercurrencies')}</h6>
                </div>
                <div className="col-auto align-self-center">
                    <a href="addcurrency.html" className="small">{t('viewall')}</a>
                </div>
    </div>
    <div className="row">
      <div className="col-12">
        <div className="card shadow-sm mb-4">
          <ul className="list-group list-group-flush bg-none">
            {coins.map((user) => (
              <>
              {user.symbol != 'GLX' &&
              
              <li key={user.id} className="list-group-item">
                <div className="row">
                  <div className="col-auto">
                    <div className="card">
                      <div className="card-body p-1">
                        <figure className="avatar avatar-44 rounded-15">
                          <img src={user.image} alt={user.name} />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col px-0">
                    <p>
                      {user.name}
                      <br />
                      <small className="text-secondary">{t('price')} : {dt[user.symbol] != undefined && dt[user.symbol].USD} </small>
                      {/* <small className="text-secondary">Price : {user.symbol == "BTC" && dt['BTC'].USD || user.symbol == "LTC" && dt['LTC'].USD || user.symbol == "DOGE" && dt['DOGE'].USD || user.symbol == "ETH" && dt['ETH'].USD || user.symbol == "MATIC" && dt['MATIC'].USD}</small> */}
                    </p>
                  </div>
                  <div className="col-auto text-end">
                    {/* <AreaChart /> */}
                    <p>
                      <small className="text-secondary">
                        <div>
                            <p>{t('balance')}</p>
                            <p>0 {user.symbol}</p>
                        </div>
                      </small>
                    </p>
                  </div>
                </div>
              </li>
                }
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default ConnectionSwiper;
