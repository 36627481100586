import React, { useState, useEffect } from 'react';
import { ArrowDownLeft, ArrowUpRight } from 'react-bootstrap-icons';
import { postHelper } from '../../helpers/apiHelper';
import { fetchData } from '../../helpers/security';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {coins} from '../../constants/coins';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

const Transactiondata = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 25; // Data per page
    const [count, setCount] = useState(25);
    const { t } = useTranslation();

    // Calculate total pages based on the count
    useEffect(() => {
        setTotalPages(Math.ceil(count / pageSize));
    }, [count]);

    // Fetch transactions for the current page
    useEffect(() => {
      const token = fetchData('user');
      const fetch  = async () => {
        const skip = (currentPage - 1) * pageSize;
        const res = await postHelper('user/transactions', { skip }, token);
        console.log(res);
        setTransactions(res.data);
        setCount(res.count);
      };
      fetch();
    }, [currentPage]);

    const [selectedCoin, setSelectedCoin] = useState(coins[0].name); // Set initial selection

  const handleSelect = (eventKey) => {
    const selected = coins.find(coin => coin.name === eventKey);
    setSelectedCoin(selected.name); // Update selected coin name
  };

    // Handler for changing pages
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Generate the page numbers for pagination (Google-style)
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5; // Max number of page links to display
        let startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
        let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

        // Adjust startPage if we are at the last few pages
        if (endPage - startPage + 1 < maxPageNumbersToShow) {
            startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
        }

        // Add page numbers to the array
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`btn ${i === currentPage ? 'btn-primary' : 'btn-light'} me-2`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    return (
      
        <>
        {transactions.length > 0 ? 
        <>
        <div className="row mb-2">
            <div className="col">
                <h6 className="title">{t('recenttransactions')} ({selectedCoin})</h6>
            </div>
            <div className="col-auto align-self-center">

            <DropdownButton
              id="dropdown-basic-button"
              title={selectedCoin}
              onSelect={handleSelect}
              style={{ color: 'black !important' }}
              className="custom-dropdown"
              align="end" 
            >
              {coins.map((coin) => (
                <Dropdown.Item
                  key={coin.id}
                  eventKey={coin.name}
                  style={{ fontSize: '11px' }}
                >
                  <img src={coin.image} alt={coin.name} width="20" height="20" className="me-2" />
                  {coin.name} ({coin.symbol})
                </Dropdown.Item>
              ))}
            </DropdownButton>

            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card shadow-sm mb-4">
                    <ul className="list-group list-group-flush bg-none">
                        {transactions.length > 0 ?
                            <>
                            {transactions.map((transaction, index) => (
                                <li key={index} className="list-group-item">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="card mbhide">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-44 rounded-15">
                                                        <img src="/logo.png" alt="logo" />
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="card mbshow">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-44 rounded-15">
                                                        {transaction.type === 0 ? <ArrowDownLeft size={24} /> : <ArrowUpRight size={24} />}
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-0">
                                            <p className='ellipsify' onClick={() => window.open(`https://explorer.gxscan.io/tx/${transaction.txid}`)}>
                                                {transaction.txid}
                                                <br />
                                                <small className="text-secondary">{transaction.pending ? t('pending') : t('confirm')}</small>
                                            </p>
                                        </div>
                                        <div className="col-auto text-end">
                                            <p>
                                                <small className="text-secondary">
                                                    <div>
                                                        <p>GLX</p>
                                                        <p>{transaction.balance.toFixed(8)} GLX</p>
                                                    </div>
                                                </small>
                                            </p>
                                        </div>
                                        <div className="col-auto mbhide">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-44 rounded-15">
                                                        {transaction.type === 0 ? <ArrowDownLeft size={24} /> : <ArrowUpRight size={24} />}
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            </> : <></>
                        }
                    </ul>
                </div>
            </div>
        </div>

        {/* Pagination Controls */}
        <div className="row mb-2">
            <div className="col text-center">
                <button
                    className="btn btn-light me-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    {t('previous')}
                </button>
                
                {renderPageNumbers()}
                
                <button
                    className="btn btn-light"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    {t('next')}
                </button>
            </div>
        </div>
        </> : <Loader /> }
        </>
    );
};

export default Transactiondata;
