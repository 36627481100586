import React from 'react';
import { useNavigate } from 'react-router-dom';

function Welcome() {
    const navigate = useNavigate();

    const handleProceed = () => {
        // Navigate to the dashboard or home page after successful registration
        navigate('/signin');
    };

    return (
        <div className="welcome-page d-flex justify-content-center align-items-center vh-100">
            <div className="welcome-card shadow-lg p-4 text-center">
                <h1 className="mb-4">Welcome to GXCOIN Wallet!</h1>
                <p className="text-secondary mb-4">
                    Your registration and verification have been successfully completed.
                    You are now part of the GXCOIN community.
                </p>
                <p className="text-secondary mb-4">
                    Get ready to explore your wallet, manage your assets, and take full control of your crypto journey.
                </p>
                <button onClick={handleProceed} className="btn btn-primary btn-lg">
                    Login to your account
                </button>
            </div>
        </div>
    );
}

export default Welcome;
