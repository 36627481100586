import React from 'react';
import { Grid, House, ArrowUpRight, ArrowLeftRight, Wallet2 } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import { menuModal } from '../reducers/actions/menuaction';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <ul className="nav nav-pills nav-justified">
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link active">
              <span>
                <House />
                <span className="nav-text">{t('home')}</span>
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/send" className="nav-link">
              <span>
                <ArrowUpRight />
                <span className="nav-text">{t('send')}</span>
              </span>
            </Link>
          </li>
          <li className="nav-item centerbutton" onClick={() => dispatch(menuModal())}>
            <button
              type="button"
              className="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#menumodal"
              id="centermenubtn"
            >
              <span className="theme-radial-gradient">
                <Grid className="size-22" />
              </span>
            </button>
          </li>
          <li className="nav-item">
            <Link to="/history" className="nav-link">
              <span>
                <ArrowLeftRight />
                <span className="nav-text">{t('history')}</span>
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/receive" className="nav-link">
              <span>
                <Wallet2 />
                <span className="nav-text">{t('wallet')}</span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
