import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import '../assets/css/style.css';
import Header from '../components/Header';
import BalanceAndStats from '../components/home/Balnce';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import MenuModal from '../components/quickmenu';
import VerifyModal from '../components/verifysend';
import ConnectionSwiper from '../components/home/coins';
import {Outlet, useLocation, Link, useNavigate} from "react-router-dom";
import withSessionCheck from '../middleware/withauthcheck';
import { postHelper, priceHelper } from '../helpers/apiHelper';
import { fetchData } from '../helpers/security';
import { ToastContainer, toast } from 'react-toastify';

function Userlayout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menu = useSelector((state) => state.menu)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = fetchData('user');
    const fetch = async () => {
      const res = await postHelper('user/userdata', {}, token);
      if (res.type === "auth") { 
        navigate('/sessionexpired');
      } 
      if (res.error) {
        toast(res.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
      }else{
        // console.log(res);
        const prices = await priceHelper();
        // console.log(prices);
        dispatch({ type: 'PRICES', payload: prices });
        dispatch({ type: 'USERDATA', payload: res });
      }
    }
    fetch();
    // console.log(menu)
  }, [])
  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
    <div className={`body-scroll ${menu.isMenuOpen ? 'menu-open' : ''}`} data-page="index">
      

      {/* Sidebar main menu */}
      <Sidebar />
      {/* Sidebar main menu ends */}

      <main className="h-100" style={{minHeight: '415px', paddingTop: '85.7188px', paddingBottom: '70px'}}>
        <Header />
        <div className="main-container container">
          <Outlet />
        </div>
      </main>

      {/* Footer starts */}
      <Footer />
      {/* Footer Ends */}

      <MenuModal />
      <VerifyModal />

    </div>
  );
}

export default withSessionCheck(Userlayout) //Userlayout