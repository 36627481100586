import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postHelper } from "../helpers/apiHelper";
import { storeData, fetchData } from "../helpers/security";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withSessionCheck from '../middleware/withauthcheck';
import html2canvas from 'html2canvas';  // Import html2canvas for screenshot functionality
import { useTranslation } from 'react-i18next';

function MnemonicSave() {
    const [mnemonic, setMnemonic] = useState('');
    const [saved, setSaved] = useState(false);
    const navigate = useNavigate();
    const {t}  = useTranslation();
    useEffect(() => {
        const fetch = async () => {
            const token = fetchData('user');
            const res = await postHelper('user/getMnemonic', {}, token);
            if (res.type === "auth") {
                navigate('/sessionexpired');
            }
            if (res.error) {
                toast(res.msg, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setMnemonic(res.mnemonic);
            }
        };

        fetch();
    }, [navigate]);

    const handleCopy = () => {
        navigator.clipboard.writeText(mnemonic)
            .then(() => {
                setSaved(true);
                toast('Mnemonic copied to clipboard!', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch(err => {
                toast.error('Failed to copy mnemonic.', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    };

    const handleScreenshot = () => {
        html2canvas(document.body).then(canvas => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'mnemonic-screenshot.png';
            link.click();
            setSaved(true);
        });
    };

    return (
        <div className="col-11 col-sm-11 col-md-8 col-lg-6 mx-auto align-self-center py-4">
            <h1 className="mb-3">
                <span className="text-secondary fw-light">{t('storeyour')}</span><br />{t('mnemonicphrase')}
            </h1>
            <p className="text-secondary mb-4">{t('stoeseedtag')}</p>

            <div className="mb-4">
                <textarea
                    className="form-control"
                    rows="2"
                    value={mnemonic}
                    readOnly
                    placeholder="Enter your mnemonic phrase here..."
                    style={{ fontSize: 'x-large' }}
                />
            </div>
            <div className="d-flex justify-content-between mb-4">
                <button type="button" className="btn btn-outline-secondary" onClick={handleCopy}>
                    {t('copyseed')}
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={handleScreenshot}>
                    {t('screenshot')}
                </button>
            </div>
            <div className="d-grid">
                {!saved ? (
                    <button type="submit" className="btn btn-primary btn-lg" disabled>
                        {t('storecon')}
                    </button>
                ) : (
                    <button type="submit" className="btn btn-primary btn-lg" onClick={() => navigate('/mnemonic')}>
                        {t('storecon')}
                    </button>
                )}
                
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default withSessionCheck(MnemonicSave);
