import React from 'react';
import {Outlet, useLocation, Link} from "react-router-dom";
import logo from '../assets/img/logo.png';
function Authlayout() {
    
    const location = useLocation();
    return (
        <div className="body-scroll d-flex flex-column h-100" data-page="signup">
            
            {/* Begin page content */}
            <main className="container-fluid h-100" style={{ minHeight: '324px' }}>
                <div className="row h-100">
                    <div className="col-11 col-sm-11 mx-auto">
                        {/* Header */}
                        <div className="row">
                            <header className="header">
                                <div className="row">
                                    <div className="col">
                                        <div className="logo-small">
                                            <img src={logo} alt="Logo" />
                                            <h5><span className="text-secondary fw-light">GXCOIN</span><br />Wallet</h5>
                                        </div>
                                    </div>
                                    <div className="col-auto align-self-center">
                                        {location.pathname === '/signup' || location.pathname === '/recover'?
                                            <Link to="/signin" >Sign in</Link>:
                                            <Link to="/signup" >Sign up</Link>}
                                        {/* <a href="signin.html">Sign in</a> */}
                                    </div>
                                </div>
                            </header>
                        </div>
                        {/* Header ends */}
                    </div>

                    <Outlet />

                </div>
            </main>

            
        </div>
    );
}

export default Authlayout;
