import { decrypt, encrypt } from "./security";


const baseUrl = "https://api.gx-wallet.com/";
// const axios = require('axios');


const postHelper = async (url, data, header) => {
    return fetch(baseUrl + url, {
        method: 'POST',
        headers: {
            'x-auth-token': header,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(encrypt(data)),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        // console.log(responseJson);
        return JSON.parse(decrypt(responseJson))
    })
    .catch((error) => {
        console.error(error);
    });
}

const priceHelper = async () => {
    try {
        const response = await fetch('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,LTC,DOGE,ETH,MATIC,BNB,USDT,DASH,TRX,ARB&tsyms=USD');
        
        const prices = await response.json();
        // console.log('Current Prices in USD:');
        // console.log(`BTC: $${prices.bitcoin.usd}`);
        // console.log(`LTC: $${prices.litecoin.usd}`);
        // console.log(`DOGE: $${prices.dogecoin.usd}`);
        // console.log(`ETH: $${prices.ethereum.usd}`);
        // console.log(`MATIC: $${prices.polygon.usd}`);
        return prices;
      } catch (error) {
        // console.error('Error fetching crypto prices:', error);
        return false;
      }
}

export {postHelper, priceHelper}